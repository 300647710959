<template>
    <div class="page">
        <div class="title">
            <div class="name">故障监测</div>
            <div class="search">
                <Select v-model="formItem.villageId" placeholder="全部小区" clearable style="width:120px">
                    <Option v-for="(item, index) in villageFormItemSelectList" :key="index" :value="item.v_village_id">
                        {{ item.v_village_name }}</Option>
                </Select>
                <Select v-model="formItem.vElevatorCode" placeholder="全部电梯" clearable style="width:120px">
                    <Option v-for="(item, index) in elevatorSelectList" :key="index" :value="item.v_elevator_code">
                        {{ item.v_elevator_name }}</Option>
                </Select>
                <Select v-model="formItem.iFaultType" placeholder="故障类型" clearable style="width:120px">
                    <Option v-for="(item, index) in iFaultTypeList" :key="index" :value="item.name">
                        {{ item.label }}</Option>
                </Select>
                <Select v-model="formItem.iStatus" placeholder="工单状态" clearable style="width:120px">
                    <Option v-for="(item, index) in iStatusList" :key="index" :value="item.name">
                        {{ item.label }}</Option>
                </Select>
                <DatePicker :value="formItem.dtReportTime" format="yyyy-MM-dd" type="date" style="width:120px" placeholder="开始时间" @on-change="formItem.dtReportTime = $event" />
                <DatePicker :value="formItem.dtEndTime" format="yyyy-MM-dd" type="date" style="width:120px" placeholder="结束时间" @on-change="onChangeEndTime" />
                <Button type="primary" @click="searchList()">查询</Button>
            </div>
        </div>
        <div class="content">
            <tblcomponents ref="tblcomponents" :formItem="formItem" :tabelConfigVal="tabelConfig" :tabelDataVal="tabelData" :tabelLoadingVal="tabelLoading" :totalRecordCntVal="totalRecordCnt" @search="searchList" data-index="vDeptId" />
        </div>
        <Modal footer-hide :mask="true" :mask-closable="false" :scrollable="true" class-name="vertical-center-modal" title="详情" v-model="modaDetail" width="1200">
            <div style="background:#fff">
                <Row :gutter="10">
                    <Col :lg="12" :md="12" :sm="12" :xs="12" class="flexVideo">
                    <Tabs size="small">
                        <TabPane label="视频" class="TimeDiv">
                            <video :src="videoUrl" controls ref="video" v-if="videoUrl != '' && videoUrl != null">
                                您的浏览器不支持video元素。
                            </video>
                            <!-- <svg-icon :icon-class="'notViodeo'" class="svg-icon_not_video" v-if="!videoUrl" /> -->
                            <br />
                            <font style="margin-left:205px" v-if="!videoUrl">暂无视频</font>
                        </TabPane>
                        <TabPane label="图片">
                            <Scroll v-if="faultImageList.length !== 0">
                                <div :key="index" dis-hover style="margin: 32px 0" v-for="(item, index) in faultImageList">
                                    <img :src="item" style="max-width:100%" />
                                </div>
                            </Scroll>
                            <!-- <svg-icon :icon-class="'notImgList'" class="svg-icon_not_video" v-if="faultImageList.length === 0" /> -->
                            <br />
                            <font style="margin-left:210px" v-if="faultImageList.length === 0">暂无图片</font>
                        </TabPane>
                    </Tabs>
                    </Col>
                    <Col :lg="6" :md="6" :sm="6" :xs="6">
                    <!-- <div>123</div> -->
                    <div class="live-progress mt16" style="position: relative">
                        <h2>处置流程</h2>
                        <div class="item" v-for="(item, index) in realData" :key="index">
                            <div class="box">
                                <h5 class="title" style="
                       overflow: hidden;
                       white-space: nowrap;
                       text-overflow: ellipsis;
                  ">
                                    {{ item.comment }}
                                </h5>
                                <div class="text">
                                    <span>{{ item.status_time }}</span>
                                    <span style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      max-width: 55px;
                    ">{{ item.handler }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Col>
                    <Col :lg="6" :md="6" :sm="6" :xs="6">
                    <CellGroup>
                        <Cell :extra="
                  faultInfoDetail ? faultInfoDetail.v_fault_id + '' : '--'
                " title="故障ID" />
                        <Cell :extra="
                  faultInfoDetail ? faultInfoDetail.v_elevator_name + '' : '--'
                " title="电梯编号" />
                        <Cell :extra="
                  faultInfoDetail.v_address
                    ? faultInfoDetail.v_address + ''
                    : '--'
                " title="电梯地址" />
                        <Cell :extra="
                  faultInfoDetail.v_elevator_type
                    ? faultInfoDetail.v_elevator_type + ''
                    : '--'
                " title="电梯类型" />
                        <Cell :extra="
                  faultInfoDetail.dt_report_time
                    ? faultInfoDetail.dt_report_time + ''
                    : '--'
                " title="上报时间" />
                        <Cell :extra="
                  faultInfoDetail.dt_end_time
                    ? faultInfoDetail.dt_end_time + ''
                    : '--'
                " title="结束时间" />
                        <Cell :extra="
                  faultInfoDetail
                    ? faultInfoDetail.i_fault_type +
                      '-' +
                      faultInfoDetail.v_fault_name +
                      ''
                    : '--'
                " title="故障类型" />
                        <Cell :extra="
                  faultInfoDetail.v_fault_second_type
                    ? faultInfoDetail.v_fault_second_type +
                      '-' +
                      faultInfoDetail.v_fault_second_name +
                      ''
                    : '--'
                " title="故障子类型" />
                        <Cell :extra="
                  faultInfoDetail ? faultInfoDetail.i_fault_num + '' : '--'
                " title="上报次数" />
                        <Cell :extra="
                  faultInfoDetail
                    ? faultInfoDetail.i_mode_status_name + ''
                    : '--'
                " title="所处服务模式" />
                        <Cell :extra="
                  faultInfoDetail.i_floor ? faultInfoDetail.i_floor + '' : '--'
                " title="故障时梯楼层" />
                        <Cell :extra="
                  faultInfoDetail ? faultInfoDetail.i_status_name + '' : '--'
                " selected title="当前故障状态" />
                    </CellGroup>
                    </Col>
                </Row>
            </div>
        </Modal>
    </div>
</template>
<script>
import {
    queryFaultList,
    getFaultDetail,
    getFaultDefinitionSearch,
    generateRescuePdf,
    generateRepairPdf,
    getElevatorFaultRealTimeByEvent
} from '@/api/repair/index.js'

import {
    getElevatorsStatus,
    searchVillageSelectList
} from '@/api/home/index.js'
export default {
    data() {
        return {
            realData: [],
            modaDetail: false,
            iFaultTypeList: [{
                    name: 'BYLX01',
                    label: '半月保'
                },
                {
                    name: 'BYLX02',
                    label: '季度保'
                },
                {
                    name: 'BYLX03',
                    label: '半年保'
                },
                {
                    name: 'BYLX04',
                    label: '年度保'
                }
            ],
            iStatusList: [{
                    name: 1,
                    label: '渠道上报'
                },
                {
                    name: 2,
                    label: '新建工单'
                },
                {
                    name: 3,
                    label: '已接单'
                },
                {
                    name: 4,
                    label: '已签到'
                },
                {
                    name: 5,
                    label: '已完成'
                },
                {
                    name: 6,
                    label: '已确认'
                },
                {
                    name: 7,
                    label: '误报'
                }
            ],

            elevatorFormItemProjectSelectList: [],
            villageFormItemSelectList: [],
            faultImageList: '',
            model1: '',
            faultInfoDetail: {},
            videoUrl: '',
            searchLoading: false,
            open: false,
            tabelConfig: [{
                    title: '电梯名称',
                    key: 'v_elevator_name'
                },

                {
                    title: '所属小区',
                    key: 'villageName'
                },
                {
                    title: '当前状态',
                    key: 'i_status',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'font',
                                params.row.i_status == 1 ?
                                '渠道上报' :
                                params.row.i_status == 2 ?
                                '新建工单' :
                                params.row.i_status == 3 ?
                                '已接单' :
                                params.row.i_status == 4 ?
                                '已签到' :
                                params.row.i_status == 5 ?
                                '已完成' :
                                params.row.i_status == 6 ?
                                '已确认' :
                                '误报'
                            )
                        ])
                    }
                },
                {
                    title: '地址',
                    key: 'v_address'
                },
                {
                    title: '故障类型',
                    key: 'v_fault_name'
                },
                {
                    title: '时间',
                    key: 'dt_report_time'
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 240,
                    align: 'center',
                    render: (h, params) => {
                        const btns = []
                        btns.push(
                            h(
                                'Button', {
                                    props: {
                                        type: 'primary',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.showDetail(params.row.v_fault_id, params.row.eventId)
                                        }
                                    }
                                },
                                '查看'
                            )
                        )

                        // btns.push(
                        //     h(
                        //         'Button', {
                        //             props: {
                        //                 type: 'primary',
                        //                 size: 'small'
                        //             },
                        //             style: {
                        //                 marginRight: '5px'
                        //             },
                        //             on: {
                        //                 click: () => {
                        //                     this.getOrderPdf(
                        //                         params.row.i_fault_type,
                        //                         params.row.eventNumber
                        //                     )
                        //                 }
                        //             }
                        //         },
                        //         '查看pdf'
                        //     )
                        // )

                        return h('div', btns)
                    }
                }
            ],
            tabelData: [{
                    v_data: '123'
                },
                {
                    v_data: '123'
                },
                {
                    v_data: '123'
                },
                {
                    v_data: '123'
                }
            ],
            tabelLoading: false,
            totalRecordCnt: 0,
            formItem: {
                villageId: '',
                buildingId: '',
                vElevatorCode: '',
                dtEndTime: this.formatDate(0) + '23:59:59',
                dtReportTime: this.formatDate(-30) + '',
                iFaultType: '',
                overdue: '',
                pageIndex: 1,
                pageSize: 10,
                vProjectId: ''
            },
            faultInfoDetail: '',
            videoUrl: '',
            faultImageList: '',
            elevatorSelectList: [],
            elevatorData: { villageId: '', vProjectId: '' },

        }
    },
    created() {
        // 获取物业部门
        this.searchList()

        searchVillageSelectList(this.buildParams({})).then(res => {
            this.villageFormItemSelectList = res.info
        })
        getFaultDefinitionSearch(1, 2).then(res => {
            const arr = []
            res.info.forEach(function(item, index) {
                arr.push({
                    label: item.faultName,
                    id: index + 2,
                    name: item.faultType
                })
            })
            this.iFaultTypeList = arr
        })
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    methods: {
        searchList() {
            this.searchLoading = true
            this.tabelLoading = true
            queryFaultList(this.buildParams(this.formItem)).then(res => {
                this.tabelData = res.info.list
                this.$refs['tblcomponents'].pageIndex = res.info.pageIndex
                this.$refs['tblcomponents'].pageSize = res.info.pageSize
                this.totalRecordCnt = res.info.totalRecordCnt
                this.tabelLoading = false
                this.searchLoading = false
            })
        },
        showDetail(faultId, eventId) {
            this.modaDetail = true
            getFaultDetail(faultId).then(res => {
                if (res.code === '0000') {
                    this.faultInfoDetail = res.info.detail
                    this.videoUrl = res.info.videoUrl
                    this.faultImageList = res.info.imageList
                }
            })
            // 进度条
            getElevatorFaultRealTimeByEvent(eventId).then(res => {
                this.realData = res.info.filter(item => {
                    return item.comment != '渠道上报'
                })
            })


        },
        getOrderPdf(faultType, number) {
            if (faultType == '09') {
                generateRescuePdf({
                    eventNumber: number
                }).then(res => {
                    if (res.info && res.info.fileUrl) {
                        window.open(res.info.fileUrl, '_blank')
                    }
                })
            } else {
                generateRepairPdf({
                    eventNumber: number
                }).then(res => {
                    if (res.info && res.info.fileUrl) {
                        window.open(res.info.fileUrl, '_blank')
                    }
                })
            }
        },
        buildParams(params) {
            params.vProjectId = this.user.user.v_project_id
            return params
        },

        onChangeEndTime(e) {
            this.formItem.dtEndTime = e + ' 23:59:59'
        }

    },
    watch: {

        "formItem.villageId": function(newObj, OldObj) {
            if (newObj) {
                this.elevatorData.villageId = newObj
                getElevatorsStatus(this.elevatorData).then(res => {
                    this.elevatorSelectList = res.info
                })
            } else {
                this.elevatorData.vElevatorCode = ''
                this.elevatorSelectList = []
            }
        }
    }
}
</script>
<style lang="less" scoped>
/deep/.ivu-modal-content {
    background-color: #f2f7fb;
}

/deep/.ivu-modal-header p,
/deep/.ivu-modal-header-inner {
    font-weight: 700;
    padding-left: 20px;
}

/deep/.ivu-modal-header {
    border: none;
}

/deep/.ivu-modal-body {
    padding-top: 0;
}

.ivu-select,
.ivu-date-picker {
    margin-right: 10px;
}

.title {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.name {
    font-size: 18px;
    font-weight: 700;
    padding-left: 20px;
}

.page {
    padding: 0 10px 0;
}

.content {
    padding: 10px;
}

.flexVideo {
    video {
        width: 100%;
    }
}

.TimeDiv::-webkit-scrollbar {
    display: none;
}

.ivu-col-offset-7 {
    margin-left: 37%;
}

/deep/ .ivu-tabs .ivu-tabs-tabpane {
    height: 400px;
}

.img-container {
    img:not(:first-child) {
        margin-top: 32px;
    }
}

.flexVideo video[data-v-009b1358] {
    height: 400px;
}

.live-progress {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 18px;

    h2 {
        margin: 16px;
    }

    &>.item {
        flex: 1;
        position: relative;
        margin-bottom: 15px;

        .box {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 50px;
            border: 1px solid rgb(134, 131, 131);
            border-radius: 25px;
            align-items: center;
            justify-content: center;

            &>div {
                width: 100%;
            }
        }

        .title {
            font-size: 18px;
            color: #515A6e;
        }

        .text {
            display: flex;
            width: 100%;
            padding: 0 10px;
            margin-top: 4px;
            font-size: 14px;
            color: #515A6e;
            justify-content: space-around;
        }

        &.active {
            background-image: linear-gradient(to right, #00ffff, #00aff8);

            .title,
            .text {
                color: #0d2f6a;
            }
        }

        &:after {
            position: absolute;
            @include center(x);
            width: 1px;
            background-color: #000;
            content: '';
            left: 50%;
            top: 50px;
            height: 15px;
        }

        &:last-child:after {
            background-color: transparent;
        }
    }
}
</style>